<template>
  <a-modal v-model="visible" :title="form.id ? 'Изменение услуги' : 'Создание услуги'" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" type="primary" @click.prevent.stop="submit" :disabled="isDisabled">
        {{ form.id ? 'Изменить' : 'Создать' }}
      </a-button>
    </template>

    <a-form-model :model="form">
      <a-form-model-item label="Описание(ru)" required>
        <a-input v-model="form.ru.name" />
      </a-form-model-item>
      <a-form-model-item label="Описание(ua)" required>
        <a-input v-model="form.ua.name" />
      </a-form-model-item>
      <a-form-model-item label="Цена(грн)" required>
        <a-input v-model="form.price" />
      </a-form-model-item>
      <a-form-model-item label="Статус" required>
        <a-select v-model="form.active">
          <a-select-option v-for="type in statuses" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Позиция" required>
        <a-input v-model="form.position" />
      </a-form-model-item>
      <a-form-model-item label="Тип пакета" required>
        <a-select v-model="form.check_type_id">
          <a-select-option v-for="type in checkTypes" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <p>Настройка пакетов</p>
      <div v-for="(item, index) in form.package" class="value_list" :key="index">
        <a-popconfirm
          title="Вы уверенны что хотите удалить?"
          ok-text="Да"
          cancel-text="Нет"
          @confirm="form.package.splice(index, 1)"
        >
          <a-icon class="remove_icon" type="close-circle" :style="{ fontSize: '16px', color: '#ef0c27' }" />
        </a-popconfirm>

        <a-form-model-item label="Статус" required>
          <a-select v-model="item.status" placeholder="please select your zone">
            <a-select-option v-for="type in statuses" :key="type.id" :value="type.id">
              {{ type.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="Пакет" required>
          <a-select v-model="item.package_id" placeholder="please select your zone">
            <a-select-option v-for="item in currentPackages" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </div>
      <a-button type="primary" @click.prevent.stop="form.package.push({ package_id: '', status: '',})">
        Добавить пакет
      </a-button>
    </a-form-model>
  </a-modal>
</template>

<script>
import statuses from '@/entities/statuses'

export default {
  name: 'EditCheckServiceModal',

  props: {
    checkTypes: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      statuses,
      visible: false,
      packages: [],
      currentPackages: [],
      form: {
        package: [],
        ru: {
          name: '',
        },
        ua: {
          name: '',
        },
      },
    }
  },

  computed: {
    isDisabled() {
      let isDisabled = false
      const form = this.form

      if (!form.ru.name || !form.ua.name || !`${form.active}` || form.active === undefined || !`${form.price}` || !`${form.position}` || !form.check_type_id) isDisabled = true

      if (!form.package.length) isDisabled = true

      for (const item of form.package) {
        if (!item.package_id || !`${item.status}`) isDisabled = true
      }

      return isDisabled
    },
  },

  methods: {
    async open(id) {
      const params = {
        per_page: 'all',
      }
      if (id) {
        try {
          this.packages = (await this.$services.get('admin/packages', { params })).data.data.package.data
          const form = (await this.$services.get(`admin/services/${id}`)).data.data.service

          form.package = [...form.packages]

          this.form = form
          this.visible = true
        } catch (e) {
          console.log(e)
        }
      } else {
        this.visible = true
        this.packages = (await this.$services.get('admin/packages', { params })).data.data.package.data
      }
    },

    close() {
      this.visible = false
      this.$emit('getList')
      this.form = {
        package: [],
        currentPackages: [],
        ru: {
          name: '',
        },
        ua: {
          name: '',
        },
      }
    },

    async submit() {
      try {
        if (this.form.id) {
          await this.$services.put(`admin/services/${this.form.id}`, this.form)
        } else {
          await this.$services.post('admin/services', this.form)
        }
        this.close()
      } catch (e) {
        console.log(e)
      }
    },
  },

  watch: {
    'form.check_type_id'(newValue, oldValue) {
      if (oldValue) this.form.package = []
      this.currentPackages = this.packages.filter(item => item.check_type_id === this.form.check_type_id)
    },
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  .value_list {
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 5px;
    padding: 16px;
    margin-bottom: 24px;
    position: relative;
  }
  .icon {
    width: 50px;
    height: 50px;
    padding: 10px;
    img {
      width: auto;
      height: 100%;
      max-width: 30px;
      margin: 0 auto;
      display: block;
    }
  }
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
  .remove_icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}
</style>
