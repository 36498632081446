<template>
  <a-modal v-model="visible" title="Изменение отчета" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" type="primary" @click.prevent.stop="submit" :disabled="isDisabled">
        Изменить
      </a-button>
    </template>
    <a-form-model :model="form">
      <a-form-model-item label="Название(ru)" required>
        <a-input v-model="form.ru.name" />
      </a-form-model-item>
      <a-form-model-item label="Название(ua)" required>
        <a-input v-model="form.ua.name" />
      </a-form-model-item>
      <a-form-model-item label="Статус" required>
        <a-select v-model="form.active">
          <a-select-option v-for="type in statuses" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Позиция" required>
        <a-input v-model="form.position" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import statuses from '@/entities/statuses'

export default {
  name: 'EditCheckTypeModal',
  data() {
    return {
      form: {
        ru: {
          name: '',
        },
        ua: {
          name: '',
        },
      },
      visible: false,
      statuses,
    }
  },
  computed: {
    isDisabled() {
      const { form } = this
      return !(form.ru.name && form.ua.name && `${form.position}` && `${form.active}`)
    },
  },
  methods: {
    async open(id) {
      try {
        this.form = (await this.$services.get(`admin/check_type/${id}`)).data.data.check_type
        this.visible = true
      } catch (e) {
        console.log(e)
      }
    },
    async submit() {
      try {
        await this.$services.put(`admin/check_type/${this.form.id}`, this.form)
        this.close()
      } catch (e) {
        console.log(e)
      }
    },
    close() {
      this.visible = false
      this.$emit('getList')
      this.form = {
        ru: {
          name: '',
        },
        ua: {
          name: '',
        },
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
}
</style>
