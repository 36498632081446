<template>
  <div>
    <a-table :columns="columns" :dataSource="list" :pagination="paginate" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id + index">
      <span slot="productUnique" slot-scope="record">
        <a v-if="record.product_alias" :href="getProductLink(record.product_alias)" target="_blank">{{ record.product_unique }}</a>
        <template v-else>{{ record.product_unique }} </template>
      </span>
      <a
        slot="id"
        slot-scope="text"
        href="javascript: void(0);"
        class="btn btn-sm btn-light"
      >{{text}}</a>
      <span slot="price" slot-scope="record"> {{ `${record.price}${record.currency}` }}</span>
      <div class="d-flex" slot="action" slot-scope="record">
        <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2 d-flex" @click="$refs.editModal.open(record)">
          <i class="fe fe-edit mr-2" />
          Изменить
        </a>
        <a-popconfirm
          title="Вы уверенны что хотите удалить?"
          ok-text="Да"
          cancel-text="Нет"
          @confirm="remove(record.id)"
        >
          <a href="javascript: void(0);" class="btn btn-sm btn-light d-flex">
            <small>
              <i class="fe fe-trash mr-2" />
            </small>
            Удалить
          </a>
        </a-popconfirm>
      </div>
    </a-table>
    <edit-order-modal ref="editModal" @getList="$emit('getList')"/>
  </div>
</template>

<script>
import EditOrderModal from '@/components/functional/checks/EditOrderModal'
import { getFrontUrl } from '@/utils'

export default {
  name: 'OrdersTable',

  components: { EditOrderModal },

  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Уникальный ID',
          dataIndex: 'unique_id',
        },
        {
          title: 'Название',
          dataIndex: 'name',
        },
        {
          title: 'Закзчик',
          dataIndex: 'user_name',
        },
        {
          title: 'Уникальный ID авто',
          scopedSlots: { customRender: 'productUnique' },
        },
        {
          title: 'Цена',
          scopedSlots: { customRender: 'price' },
        },
        {
          title: 'Статус',
          dataIndex: 'status',
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },

  methods: {
    async remove(id) {
      try {
        await this.$services.delete(`/admin/orders/${id}`)
        this.$emit('getList')
      } catch (e) {
        console.log(e)
      }
    },

    getProductLink(alias) {
      return `${getFrontUrl()}/auto/${alias}`
    },
  },
}
</script>
