<template>
  <div>
    <p>Услуги</p>
    <a-button class="ml-auto d-block mb-2" type="primary" @click="$refs.editModal.open()">
      Добавть
    </a-button>
    <a-table :columns="columns" :dataSource="list" :pagination="paginate" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id + index">
      <a
        slot="id"
        slot-scope="text"
        href="javascript: void(0);"
        class="btn btn-sm btn-light"
      >
        {{ text }}
      </a>
      <span slot="active" slot-scope="record"> {{ record.active ? 'Активная' : 'Не активная' }}</span>
      <span slot="date" slot-scope="record"> {{ record.created_at | getFormattedDate }}</span>
      <div class="d-flex" slot="action" slot-scope="record">
        <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2 d-flex" @click="$refs.editModal.open(record.id)">
          <i class="fe fe-edit mr-2" />
          Изменить
        </a>
        <a-popconfirm
          title="Вы уверенны что хотите удалить?"
          ok-text="Да"
          cancel-text="Нет"
          @confirm="remove(record.id)"
        >
          <a href="javascript: void(0);" class="btn btn-sm btn-light d-flex">
            <small>
              <i class="fe fe-trash mr-2" />
            </small>
            Удалить
          </a>
        </a-popconfirm>
      </div>
    </a-table>

    <edit-check-service-modal :checkTypes="checkTypes" ref="editModal" @getList="$emit('getList')"/>
  </div>
</template>

<script>
import EditCheckServiceModal from '@/components/functional/checks/settings/EditCheckServiceModal'

export default {
  name: 'ChecksServicesTable',

  components: {
    EditCheckServiceModal,
  },

  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
    checkTypes: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Название типа',
          dataIndex: 'check_type_name',
        },
        {
          title: 'Описание',
          dataIndex: 'name',
        },
        {
          title: 'Статус',
          scopedSlots: { customRender: 'active' },
        },
        {
          title: 'Позиция',
          dataIndex: 'position',
        },
        {
          title: 'Цена(грн)',
          dataIndex: 'price',
        },
        {
          title: 'Дата создания',
          scopedSlots: { customRender: 'date' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },

  methods: {
    async remove(id) {
      try {
        await this.$services.delete(`/admin/services/${id}`)
        this.$emit('getList')
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
