<template>
  <div>
    <p>Типы проверок</p>
    <a-table :columns="columns" :dataSource="list" :pagination="paginate" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id + index">
      <a
        slot="id"
        slot-scope="text"
        href="javascript: void(0);"
        class="btn btn-sm btn-light"
      >{{text}}</a>
      <span slot="active" slot-scope="record"> {{ record.active ? 'Активная' : 'Не активная' }}</span>
      <span slot="date" slot-scope="record"> {{ record.created_at | getFormattedDate }}</span>
      <span slot="action" slot-scope="record">
        <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="$refs.editModal.open(record.id)">
          <i class="fe fe-edit mr-2 mb-2" />
          Изменить
        </a>
      </span>
    </a-table>
    <edit-check-type-modal ref="editModal" @getList="$emit('getList')"/>
  </div>
</template>

<script>
import EditCheckTypeModal from '@/components/functional/checks/settings/EditCheckTypeModal'

export default {
  name: 'ChecksTypesTable',
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Название',
          dataIndex: 'name',
        },
        {
          title: 'Статус',
          scopedSlots: { customRender: 'active' },
        },
        {
          title: 'Позиция',
          dataIndex: 'position',
        },
        {
          title: 'Дата создания',
          scopedSlots: { customRender: 'date' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
  components: { EditCheckTypeModal },
}
</script>

<style scoped>

</style>
