<template>
  <div>
    <orders-table :list="list" :paginate="pagination" @handleTableChange="handleTableChange" @getList="getList" />
  </div>
</template>

<script>

import OrdersTable from '@/components/functional/checks/OrdersTable'

export default {
  name: 'ChecksOrder',
  data() {
    return {
      list: [],
      pagination: {
        current: 1,
        total: 1,
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const params = {
        page: this.pagination.current,
      }

      try {
        const data = (await this.$services.get('admin/orders', { params })).data.data.orders

        this.list = data.data
        this.pagination = {
          current: data.current_page,
          total: data.total,
          pageSize: data.per_page,
        }
      } catch (e) {
        console.log(e)
      }
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current
      this.getList()
    },
  },
  components: { OrdersTable },
}
</script>

<style scoped>

</style>
