<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Функционал: Проверки авто</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Список заявок</h5>
        </div>
      </div>
      <div class="card-body">
        <a-tabs default-active-key="1" tab-position="top">
          <a-tab-pane key="1" tab="Список заявок">
            <checks-order />
          </a-tab-pane>
          <a-tab-pane key="2" tab="Настройки проверок">
            <checks-settings />
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import ChecksOrder from '@/components/functional/checks/ChecksOrder'
import ChecksSettings from '@/components/functional/checks/ChecksSettings'

export default {
  name: 'index',

  components: {
    ChecksOrder,
    ChecksSettings,
  },
}
</script>
