<template>
  <div>
    <checks-types-table :list="types.data" :paginate="types.pagination" @handleTableChange="handleTableChangeTypes" @getList="getTypes" />
    <checks-services-table :checkTypes="types.data" :list="services.data" :paginate="services.pagination" @handleTableChange="handleTableChangeServices" @getList="getServices" />
    <checks-packages-table :checkTypes="types.data" :list="packages.data" :paginate="packages.pagination" @handleTableChange="handleTableChangePackages" @getList="getPackages" />
  </div>
</template>

<script>
import ChecksTypesTable from '@/components/functional/checks/settings/ChecksTypesTable'
import ChecksServicesTable from '@/components/functional/checks/settings/ChecksServicesTable'
import ChecksPackagesTable from '@/components/functional/checks/settings/ChecksPackagesTable'

export default {
  name: 'ChecksSettings',

  components: {
    ChecksTypesTable,
    ChecksServicesTable,
    ChecksPackagesTable,
  },

  data() {
    return {
      types: {
        pagination: {
          current: 1,
          total: 1,
          pageSize: 1,
        },
        data: [],
      },
      packages: {
        pagination: {
          current: 1,
          total: 1,
          pageSize: 1,
        },
        data: [],
      },
      services: {
        pagination: {
          current: 1,
          total: 1,
          pageSize: 1,
        },
        data: [],
      },
    }
  },

  created() {
    Promise.all([this.getPackages(), this.getServices(), this.getTypes()])
  },

  methods: {
    async getPackages() {
      const params = {
        page: this.packages.pagination.current,
      }
      try {
        const packages = (await this.$services.get('admin/packages', { params })).data.data.package

        this.packages.data = packages.data
        this.packages.pagination = {
          current: packages.pagination.current_page,
          total: packages.pagination.total,
          pageSize: packages.pagination.per_page,
        }
      } catch (e) {
        console.log(e)
      }
    },
    handleTableChangeTypes(pagination) {
      this.types.pagination.current = pagination.current
      this.getTypes()
    },
    handleTableChangeServices(pagination) {
      this.services.pagination.current = pagination.current
      this.getServices()
    },
    handleTableChangePackages(pagination) {
      this.packages.pagination.current = pagination.current
      this.getPackages()
    },
    async getServices() {
      const params = {
        page: this.services.pagination.current,
      }
      try {
        const services = (await this.$services.get('admin/services', { params })).data.data.services

        this.services.data = services.data
        this.services.pagination = {
          current: services.pagination.current_page,
          total: services.pagination.total,
          pageSize: services.pagination.per_page,
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getTypes() {
      const params = {
        page: this.types.pagination.current,
      }
      try {
        const checkTypes = (await this.$services.get('admin/check_type', { params })).data.data.check_type

        this.types.data = checkTypes.data
        this.types.pagination = {
          current: checkTypes.pagination.current_page,
          total: checkTypes.pagination.total,
          pageSize: checkTypes.pagination.per_page,
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
