<template>
  <a-modal v-model="visible" title="Изменение отчета" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" type="primary" @click.prevent.stop="submit" :disabled="isDisabled">
        Изменить
      </a-button>
    </template>
    <a-form-model :model="form">
      <a-form-model-item label="Статус" required>
        <a-select v-model="form.order_status_id">
          <a-select-option v-for="type in statuses" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item class="text_area" label="Отчет(pdf)" required>
        <a-upload
          :disabled="$route.query.is_preview"
          list-type="picture-card"
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          :file-list="fileList"
          @change="handleChangeQuote"
          :before-upload="beforeUpload"
        >
          <div v-if="fileList.length < 1">
            <a-icon type="plus" />
            <div class="ant-upload-text">
              Upload
            </div>
          </div>
        </a-upload>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: 'EditOrderModal',
  data() {
    return {
      form: {},
      fileList: [],
      newReport: '',
      visible: false,
      statuses: [
        {
          name: 'Новый',
          id: 1,
        },
        {
          name: 'В обработке',
          id: 2,
        },
        {
          name: 'Завершен',
          id: 3,
        },
      ],
      fileIsValid: true,
    }
  },

  computed: {
    isDisabled() {
      return !this.form.status
    },
  },

  methods: {
    open(record) {
      this.visible = true
      this.form = record

      if (this.form.report) {
        this.fileList.push({
          url: this.form.report,
          uid: '-4',
          name: 'report.pdf',
          status: 'done',
        })
      }
    },

    handleChangeQuote({ fileList }) {
      if (!this.fileIsValid) {
        return
      }

      this.fileList = fileList
      if (fileList.length) {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(fileList[0].originFileObj)
        fileReader.addEventListener('load', () => {
          this.newReport = fileReader.result
        })
      }
    },

    beforeUpload(file) {
      const pdf = file.type === 'application/pdf'
      if (!pdf) {
        this.fileIsValid = false
        this.$message.error('Выберите PDF файл!')
        return
      }
      this.fileIsValid = true
    },

    async submit() {
      if (this.fileList.length) {
        if (this.newReport) this.form.report = this.newReport
      }

      try {
        await this.$services.put(`admin/orders/${this.form.id}`, this.form)
        this.close()
      } catch (e) {
        console.log(e)
      }
    },

    close() {
      this.visible = false
      this.form = {}
      this.fileList = []
      this.$emit('getList')
    },
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
  ::v-deep .ant-upload-list-item-error {
    border-color: #1890ff;
  }
  ::v-deep .ant-upload-list-item-name {
    color: #1890ff;
  }
}
</style>
