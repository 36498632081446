<template>
  <a-modal v-model="visible" title="Изменение отчета" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" type="primary" @click.prevent.stop="submit" :disabled="isDisabled || request">
        {{ form.id ? 'Изменить' : 'Создать' }}
      </a-button>
    </template>
    <a-form-model :model="form">
      <a-form-model-item label="Название(ru)" required>
        <a-input v-model="form.ru.name" />
      </a-form-model-item>
      <a-form-model-item label="Название(ua)" required>
        <a-input v-model="form.ua.name" />
      </a-form-model-item>
      <a-form-model-item label="Описание(ru)" required>
        <a-input v-model="form.ru.description" />
      </a-form-model-item>
      <a-form-model-item label="Описание(ua)" required>
        <a-input v-model="form.ua.description" />
      </a-form-model-item>
      <a-form-model-item label="Цена(грн)" required>
        <a-input v-model="form.price" />
      </a-form-model-item>
      <a-form-model-item label="Статус" required>
        <a-select v-model="form.active">
          <a-select-option v-for="type in statuses" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Тип пакета" required>
        <a-select v-model="form.type_service_id">
          <a-select-option v-for="type in types" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Тип проверки" required>
        <a-select v-model="form.check_type_id">
          <a-select-option v-for="type in checkTypes" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Позиция" required>
        <a-input v-model="form.position" />
      </a-form-model-item>
      <a-form-model-item class="text_area" label="Пример отчета(pdf)" required>
        <a-upload
          :disabled="$route.query.is_preview"
          list-type="picture-card"
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          :file-list="fileList"
          @change="handleChangeQuote"
          :before-upload="beforeUpload"
        >
          <div v-if="fileList.length < 1">
            <a-icon type="plus" />
            <div class="ant-upload-text">
              Upload
            </div>
          </div>
        </a-upload>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import statuses from '@/entities/statuses'

export default {
  props: {
    checkTypes: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

  name: 'EditCheckPackageModal',

  data() {
    return {
      types: [
        {
          name: 'Нельзя выбирать услуги',
          id: 1,
        },
        {
          name: 'Можно выбирать услуги',
          id: 2,
        },
      ],
      form: {
        ru: {
          name: '',
          description: '',
        },
        ua: {
          name: '',
          description: '',
        },
      },
      visible: false,
      fileList: [],
      newReport: '',
      statuses,
      fileIsValid: true,
      request: false,
    }
  },

  computed: {
    isDisabled() {
      const { form } = this
      return !(form.ru.name &&
        form.ua.name &&
        `${form.position}` &&
        `${form.active}` &&
        form.ua.description &&
        form.ru.description &&
        this.fileList.length &&
        form.type_service_id &&
        form.price &&
        form.type_service_id
      )
    },
  },

  methods: {
    async open(id) {
      if (id) {
        try {
          const form = (await this.$services.get(`admin/packages/${id}`)).data.data.package

          form.ru = {
            name: form.ru.name,
            description: form.ru.description,
          }
          form.ua = {
            name: form.ua.name,
            description: form.ua.description,
          }
          delete form.type_service
          delete form.created_at
          delete form.updated_at
          delete form.name
          delete form.description

          this.form = form
          this.visible = true

          if (this.form.report) {
            this.fileList.push({
              url: this.form.report,
              uid: '-4',
              name: 'report.pdf',
              status: 'done',
            })
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        this.visible = true
      }
    },

    handleChangeQuote({ fileList }) {
      if (!this.fileIsValid) {
        return
      }

      this.fileList = fileList
      if (fileList.length) {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(fileList[0].originFileObj)
        fileReader.addEventListener('load', () => {
          this.newReport = fileReader.result
        })
      }
    },

    beforeUpload(file) {
      const pdf = file.type === 'application/pdf'
      if (!pdf) {
        this.fileIsValid = false
        this.$message.error('Выберите PDF файл!')
        return
      }
      this.fileIsValid = true
    },

    async submit() {
      this.request = true

      if (this.fileList.length) {
        if (this.newReport) this.form.report = this.newReport
      }

      if (this.form.id) {
        try {
          await this.$services.put(`admin/packages/${this.form.id}`, this.form)
          this.close()
        } catch (e) {
          console.log(e)
        }
      } else {
        try {
          this.form.currency_id = 1
          await this.$services.post('admin/packages', this.form)
          this.close()
        } catch (e) {
          console.log(e)
        } finally {
          this.request = false
        }
      }
    },

    close() {
      this.visible = false
      this.$emit('getList')
      this.form = {
        ru: {
          name: '',
        },
        ua: {
          name: '',
        },
      }
      this.fileList = []
      this.request = false
    },
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
}
</style>
