<template>
  <div>
    <p>Пакеты</p>
    <a-button class="ml-auto d-block mb-2" type="primary" @click="$refs.editModal.open()">
      Добавть
    </a-button>
    <a-table :columns="columns" :dataSource="list" :pagination="paginate" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id + index">
      <a
        slot="id"
        slot-scope="text"
        href="javascript: void(0);"
        class="btn btn-sm btn-light"
      >{{text}}</a>
      <span slot="active" slot-scope="record"> {{ record.active ? 'Активная' : 'Не активная' }}</span>
      <span slot="date" slot-scope="record"> {{ record.created_at | getFormattedDate }}</span>
      <span slot="checkType" slot-scope="record"> {{ record.type_service_id === 1 ? 'Нельзя выбирать услуги' : 'Можно выбирать услуги' }}</span>
      <span slot="action" slot-scope="record">
        <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2 pb-2" @click="$refs.editModal.open(record.id)">
          <i class="fe fe-edit mr-2 mb-2" />
          Изменить
        </a>
        <a-popconfirm
          title="Вы уверенны что хотите удалить?"
          ok-text="Да"
          cancel-text="Нет"
          @confirm="remove(record.id)"
        >
              <a href="javascript: void(0);" class="btn btn-sm btn-light">
                <small>
                  <i class="fe fe-trash mr-2 pb-2" />
                </small>
                Удалить
              </a>
        </a-popconfirm>
      </span>
    </a-table>
    <edit-check-package-modal ref="editModal" :checkTypes="checkTypes" @getList="$emit('getList')"/>
  </div>
</template>

<script>
import EditCheckPackageModal from '@/components/functional/checks/settings/EditCheckPackageModal'

export default {
  name: 'ChecksPackagesTable',
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
    checkTypes: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Название',
          dataIndex: 'name',
        },
        {
          title: 'Тип',
          scopedSlots: { customRender: 'checkType' },
        },
        {
          title: 'Статус',
          scopedSlots: { customRender: 'active' },
        },
        {
          title: 'Позиция',
          dataIndex: 'position',
        },
        {
          title: 'Цена(грн)',
          dataIndex: 'price',
        },
        {
          title: 'Дата создания',
          scopedSlots: { customRender: 'date' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
  methods: {
    async remove(id) {
      try {
        await this.$services.delete(`/admin/packages/${id}`)
        this.$emit('getList')
      } catch (e) {
        console.log(e)
      }
    },
  },
  components: { EditCheckPackageModal },
}
</script>

<style scoped>

</style>
